@if ((isRedirectPage$ | async) === false) {
    <header [class.home-header]="homePage$ | async" *ngIf="isWebView === false"></header>
    <app-second-header
        *ngIf="isWebView === false && (landingPage$ | async) === false"
        [showGreeting]="showGreetings$ | async">
    </app-second-header>
}
<section [class.page]="(isRedirectPage$ | async) === false">
    <ng-container *ngIf="(isAppLoading$ | async) === false; else globalLoader">
        <router-outlet></router-outlet>
    </ng-container>
</section>
@if ((isRedirectPage$ | async) === false) {
    <footer *ngIf="isWebView === false"></footer>
}

<ng-template #globalLoader>
    <app-loader class="global-loader"></app-loader>
</ng-template>

<router-outlet name="payment"></router-outlet>

